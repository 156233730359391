:root {
  --background: white;
  --text: #333;
  --home: #dbddd0;
  --default: #8e8e8e;
  --verde_100: #4caf50;
  --verde_200: #0b813c;
  --verde_400: #003e1f;
  --vermelho_100: #f44336f7;
  --vermelho_200: rgb(184, 34, 34);
  --vermelho_300: #a12c24f7;
  --vermelho_400: rgb(95, 25, 25);
  --amarelo_100: #fcd100;
  --amarelo_300: #ef9f00;
  --amarelo_500: #aa750a;
  --laranja_500: #f05923;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: var(--text);
}
li {
  list-style-type: none;
}
h3 {
  margin-top: 1rem;
}
a {
  color: var(--text);
  text-decoration: none;
}
textarea {
  border: 1px solid rgb(61, 61, 61);
  border-radius: 10px;
  resize: none;
  padding: 10px;
  font-size: 16px;
}

textarea:focus {
  outline-color: var(--verde_200);
}
select {
  border: 1px solid rgb(61, 61, 61);
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}
select:focus {
  outline-color: var(--verde_200);
}
input {
  border: 1px solid rgb(61, 61, 61);
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}
input:focus {
  outline-color: var(--verde_200);
}
@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}
@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}
table {
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
}
table th {
  background-color: var(--verde_100);
  color: white;
  padding: 10px;
}
tr:nth-child(even) {
  background: #fff;
}
tr:nth-child(odd) {
  background: #f2f2f2;
}

table tbody td {
  padding: 10px;
  text-align: center;
}
table,
th,
td {
  border: 1px solid #ccc;
  padding: 5px;
}
