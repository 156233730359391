.hidden {
  display: none;
}
.loading {
  display: flex;
  justify-content: center;
}
.content a button {
  margin-bottom: 5px;
}
/* .content .wrap {
  border: 1px solid #000;
  padding: 1rem;
  width: 100%;
} */
.content table {
  margin: 20px 0;
}
.content .section_button {
  display: flex;
  justify-content: center;
}
.content .section_button button {
  margin: 10px 10px 0 0;
}
