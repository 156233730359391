.content .wrap_coleta {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  background-color: var(--home);
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 2px solid rgb(109, 108, 108);
}
