.container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.section_login .form {
  display: flex;
  flex-direction: column;
  margin: 1rem 0px;
}
.section_login .form input {
  width: 300px;
  margin-top: 10px;
}
.section_login .form button {
  margin-top: 10px;
}
