.loading {
  display: flex;
  justify-content: center;
}
.content .btn_footer {
  margin-top: 20px;
}
.btn_footer .backButton {
  background-color: var(--verde_200);
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
.btn_footer .backButton:hover {
  background-color: var(--verde_400);
}
.btn_footer .backButton i {
  color: #fff;
}
.content table {
  margin-bottom: 10px;
}
.content .wrap_roteiro_tipo1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #90bcb3;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid var(--verde_200);
  margin-bottom: 20px;
  font-size: 16px;
}
.link_to_nextPage > span i {
  font-size: 30px;
}
.content .wrap_roteiro_tipo2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9a2ad;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #a00018;
  margin-bottom: 20px;
  font-size: 16px;
}
.content .wrap_roteiro_tipo3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbe192;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #f1bc1a;
  margin-bottom: 20px;
  font-size: 16px;
}
