.input {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  padding-left: 5px;
  width: 100%;
  background-color: white;
  color: #000;
}
label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.452);
}
