
.input {
  border: 1px solid rgb(61, 61, 61);
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  width: 7em;
  margin-bottom: 2px;
  margin-right: 5px;
}

.loading {
  display: flex;
  justify-content: center;
}
.content .wrap_roteiro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--home);
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 2px solid rgb(109, 108, 108);
}
.content .wrap_roteiro:hover {
  color: white;
  background-color: var(--verde_100);
}
.content .wrap_roteiro .link_to_nextPage > span i {
  font-size: 30px;
}
