.button_primary {
  cursor: pointer;
  background-color: var(--verde_200);
  border: 0;
  border-radius: 5px;
  color: white;
  transition: 0.2s;
  font-size: 16px;
  padding: 10px;
}
.button_primary:disabled {
  background-color: rgba(0, 0, 0, 0.445);
}
.button_primary:disabled:hover {
  background-color: rgba(0, 0, 0, 0.445);
  cursor: not-allowed;
}
.button_primary:focus {
  outline: 0;
}
.button_primary:hover {
  background-color: var(--verde_400);
  color: white;
}

.button_secondary {
  cursor: pointer;
  background-color: var(--amarelo_300);
  border: 0;
  border-radius: 5px;
  color: white;
  transition: 0.2s;
  font-size: 16px;
  padding: 10px;
}
.button_secondary:disabled {
  background-color: rgba(0, 0, 0, 0.445);
}
.button_secondary:disabled:hover {
  background-color: rgba(0, 0, 0, 0.445);
  cursor: not-allowed;
}
.button_secondary:focus {
  outline: 0;
}
.button_secondary:hover {
  background-color: var(--amarelo_500);
  color: white;
}

/*  */

.button_tertiary {
  cursor: pointer;
  background-color: var(--vermelho_100);
  border: 0;
  border-radius: 5px;
  color: white;
  transition: 0.2s;
  font-size: 16px;
  padding: 10px;
}
.button_tertiary:disabled {
  background-color: rgba(0, 0, 0, 0.445);
}
.button_tertiary:disabled:hover {
  background-color: rgba(0, 0, 0, 0.445);
  cursor: not-allowed;
}
.button_tertiary:focus {
  outline: 0;
}
.button_tertiary:hover {
  background-color: var(--vermelho_200);
  color: white;
}


.button_blue {
  cursor: pointer;
  background-color: #361dc6;
  border: 0;
  border-radius: 5px;
  color: white;
  transition: 0.2s;
  font-size: 16px;
  padding: 10px;
}
.button_blue:disabled {
  background-color: rgba(0, 0, 0, 0.445);
}
.button_blue:disabled:hover {
  background-color: rgba(0, 0, 0, 0.445);
  cursor: not-allowed;
}
.button_blue:focus {
  outline: 0;
}
.button_blue:hover {
  background-color: #1472ca;
  color: white;
}
