.loading {
  display: flex;
  justify-content: center;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.content .btn_footer {
  margin-top: 20px;
}
.content .btn_footer .backButton {
  background-color: var(--verde_200);
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
.btn_footer .backButton:hover {
  background-color: var(--verde_400);
}
.content .btn_footer .backButton i {
  color: #fff;
}
.content .wrap_coleta {
  padding: 15px;
  background-color: var(--home);
  border-radius: 10px;
  font-size: 16px;
  margin: 15px 0px;
  border: 2px solid rgb(41, 40, 40);
}
.content .wrap_coleta .detalhe_coleta textarea {
  width: 100%;
  font-size: 16px;
}
.content .wrap_coleta .detalhe_coleta input {
  margin-top: 15px;
}
.content .wrap_btn_coleta {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.content .wrap_btn_coleta button {
  width: 200px;
  margin-top: 5px;
}

.content .wrap_btn_coleta button a {
  color: white;
}
.content .wrap_btn_coleta .right_btn {
  display: flex;
  flex-direction: column;
}
.content .wrap_coleta .imgBtn {
  margin-top: 5px;
}
.content .wrap_coleta .imgBtn .selectImg {
  display: none;
}
.content .wrap_coleta .imgBtn .labelForImg {
  cursor: pointer;
  background-color: var(--verde_200);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  margin-top: 5px;
}
.content .wrap_coleta .imgBtn .labelForImg:hover {
  background-color: var(--verde_400);
}
.content .wrap_coleta .imgBtn .labelForImg i {
  color: white;
}
.content .wrap_coleta .detalhe_coleta .previewImg {
  margin-top: 10px;
}
.content .wrap_coleta .detalhe_coleta .previewImg button {
  width: 200px;
}
@media (max-width: 475px) {
  .content .wrap_coleta .detalhe_coleta input {
    width: 100%;
    margin-bottom: 10px;
  }
  .content .wrap_btn_coleta {
    flex-direction: column;
    /* align-items: center; */
  }
  .content .wrap_btn_coleta .left_btn {
    margin-bottom: 5px;
  }
  .content .wrap_btn_coleta button {
    width: 100%;
  }
  .content .wrap_coleta .imgBtn .labelForImg {
    width: 100%;
  }
  .content .wrap_coleta .detalhe_coleta .previewImg button {
    width: 100%;
  }
}
