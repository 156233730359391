.content tbody tr td a i {
  color: var(--verde_200);
}
.content tbody tr td a i:hover {
  color: var(--verde_400);
}
.content .loading {
  display: flex;
  justify-content: center;
}
