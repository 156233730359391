.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .content .tableComprovanteDePagamento {
  margin-top: 1rem;
  width: 100%;
} */
/* .content .tableComprovanteDePagamento tr {
  text-align: center;
} */
.content tbody tr td a i {
  color: var(--verde_200);
}
.content tbody tr td a i:hover {
  color: var(--verde_400);
}
@media (max-width: 992px) {
  /* .content .tableComprovanteDePagamento thead tr > th {
    font-size: 10px;
  }
  .content .tableComprovanteDePagamento tbody tr > td {
    font-size: 10px;
  } */
  .th_tr_Responsive {
    display: none;
  }
}
