.hidden {
  display: none;
}
.loading {
  display: flex;
  justify-content: center;
}
.content a button {
  margin-bottom: 5px;
}
.content .btn_group {
  display: flex;
  flex-direction: column;
}
/* .content .wrap {
    border: 1px solid #000;
    padding: 1rem;
    width: 100%;
  } */
.content table {
  margin: 20px 0;
}
.content .section_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: center; */
}
.content .section_button button {
  margin: 10px 10px 0 0;
}
@media (max-width: 475px) {
  .content .section_button button {
    width: 100%;
  }
  .content .section_button {
    flex-direction: column;
  }
}
