h3:first-child {
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.content .loading {
  display: flex;
  align-items: center;
  justify-content: center;
}


.content .form {
  display: flex;
  /* flex-direction: row; */
  /* align-items: stretch; */
}

.content .form .campanha{
  margin-bottom: 30px;
}


.content .form {
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  /* align-items: stretch; */
}

form section{
  margin: 10px;

}



.content .form section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 400px;
}


.detalhe {
  padding: 0px;
}
/*  */
.sectionButton {
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .form .content section {
    width: 3400px;
  }
  .form .content .sectionButton a button {
    width: 300px;
  }
}
