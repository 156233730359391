h3:first-child {
  margin-bottom: 1rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*.content table thead tr th {
  text-align: left;
} */
.content table tr th:last-child {
  text-align: center;
}

.content table tbody tr td a i {
  color: var(--verde_200);
}
.content table tbody tr td a i:hover {
  color: var(--verde_400);
}
.content table {
  width: 100%;
}
/*.content table tbody tr td.colunaCenter {
	text-align: center;
}
*/
