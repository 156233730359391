h3:first-child {
  margin-bottom: 1rem;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
}
.col {
  grid-column: 1 / 7;
}
.col_1 {
  grid-column-start: span;
  grid-column-end: 2;
}
.col_6 {
  grid-column: span 5;
}
/*.content {
  display: flex;
  flex-direction: column;
} */
.content .field_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
/*section div input,
.content section div {
  display: flex;
  flex-direction: column;
}
textarea {
  padding: 1rem;
}
.coletaOleos {
  display: flex;
}
 .coletaOleos div:first-child input {
  margin-right: 20px;
  text-align: center;
  -webkit-appearance: none;
}
.coletaOleos div:last-child {
  flex: 1;
} */
.btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btns button {
  margin: 0 20px;
  width: 200px;
}
/*.btns button:last-child {
  background-color: var(--red);
}
.btns button:last-child:hover {
  background-color: var(--red-dark);
} */
@media (max-width: 450px) {
  /* .coletaOleos {
    flex-direction: column;
  } 
  .coletaOleos div:first-child input {
    width: 100%;
    text-align: left;
  } 
  .coletaOleos div {
    margin-right: 0;
  } */
  .btns {
    flex-direction: column;
  }
  .btns button {
    margin-bottom: 10px;
    width: 100%;
  }
}
