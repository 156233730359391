h3:first-child {
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 420px;
}
.form .content section:last-child {
  margin-top: 10px;
}
.form .content section label {
  margin-top: 10px;
}

/*  */
.link {
  margin-top: 10px;
  display: block;
  text-align: center;
  color: var(--verde_200);
  font-size: 1rem;
}
.link:hover i,
.link:hover {
  color: var(--verde_400);
}
.link i {
  margin-right: 10px;
  color: var(--verde_200);
}
/*  */
@media (max-width: 600px) {
  .content section {
    width: 300px;
  }
}
