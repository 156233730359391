h3:first-child {
  margin-bottom: 1rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
/*.formUpdateConta {
  display: flex;
  flex-direction: column;
  align-items: center;
}
*/
.formUpdateConta section.sectionInput {
  display: flex;
  flex-direction: column;
  width: 420px;
}
.formUpdateConta .sectionInput a {
  text-align: center;
}
.formUpdateConta section.sectionInput button {
  margin-top: 15px;
}
.formUpdateConta section.sectionInput label {
  font-size: 0.9rem;
  margin-top: 10px;
}
@media (max-width: 600px) {
  .formUpdateConta section.sectionInput {
    width: 300px;
  }
  .content {
    align-items: center;
  }
}
