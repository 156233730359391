.hidden {
  display: none;
}
.modal {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(10, 10, 10, 0.86);
  padding: 1rem;
}
.modal .modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal .modal_header h3 {
  color: white;
}
.modal .modal_header span {
  cursor: pointer;
}
.modal .modal_header span i {
  color: white;
}
.modal_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 8rem);
}
.modal_card {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
}
