h3:first-child {
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.content .loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content .form section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 420px;
}
/*  */
.sectionButton {
  text-align: center;
}
@media (max-width: 600px) {
  .form .content section {
    width: 300px;
  }
  .form .content .sectionButton a button {
    width: 300px;
  }
}
