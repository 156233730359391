.hidden {
  display: none;
}
.loading {
  display: flex;
  justify-content: center;
}
.content a button {
  margin-bottom: 5px;
}
.content .section_form {
  position: relative;
}
.content .section_form .preview_logotipo {
  /* margin: 15px 15px 0 0; */
  /* margin: 15px 0 0 15px; */
  position: absolute;

  top: 1rem;
  /* top: 1rem;*/
  left: 84%;
}

/* .content .wrap {
  border: 1px solid #000;
  padding: 1rem;
  width: 100%;
} */
.content table {
  margin: 20px 0;
}
.content .section_button {
  display: flex;
  justify-content: center;
}
.content .section_button button {
  margin: 10px 10px 0 0;
}
.content .selectImg {
  display: none;
}
.content .labelForImg {
  cursor: pointer;
  margin-top: 15px;
  background-color: var(--verde_200);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.content .labelForImg i {
  color: white;
}
/*.content .previewLogotipo {/*
   width: 100%;
  background-color: rgba(104, 100, 104, 0.089);
  /* height: 150px; 
} */
