.loading {
  display: flex;
  justify-content: center;
}
.content .wrap_roteiro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--home);
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 2px solid rgb(109, 108, 108);
}
.content .section_button {
  margin: 10px 0;
}
.content .section_button button {
  margin-right: 10px;
}
.content .wrap_roteiro:hover {
  color: white;
  background-color: var(--verde_100);
}
.content .wrap_roteiro .link_to_nextPage > span i {
  font-size: 30px;
}
