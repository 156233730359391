.hidden {
  display: none;
}
.show {
  display: table-cell;
  vertical-align: inherit;
}

h3:first-child {
  margin-bottom: 1rem;
}
/*  */
.btnTitle button {
  width: 200px;
  margin-bottom: 10px;
}
/*  */
.content {
  display: flex;
  align-items: center;
}
.content .tableValidacaoDeColeta {
  width: 100%;
}
/*.content .tableValidacaoDeColeta tbody tr td.tableValidacaoDeColetatd {
  text-align: center;
} */
.check {
  display: flex;
  font-size: 14px;
  color: #000;
  align-items: center;
}
.content .tableValidacaoDeColeta tbody input[type='radio'] {
  margin-right: 5px;
}
/*.content .tableValidacaoDeColeta tbody tr td.showTd {
  display: block;
} */
/*.content .tableValidacaoDeColeta tbody tr:last-child {
  text-align: center;
} */
@media (max-width: 992px) {
  .check {
    font-size: 10px;
  }
  .th_tr_Responsive {
    display: none;
  }
}
