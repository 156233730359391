.header {
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.411);
  border-bottom-style: outset;
  padding: 1rem;
}
.header .logo {
  display: flex;
  align-items: center;
}
.header .logo a {
  display: flex;
  align-items: center;
}
.header .logo a img {
  width: 60px;
}
.header .logo a strong {
  font-size: 15px;
}
.header .profile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.header .profile span a > strong {
  margin-left: 5px;
}

.header .profile img {
  width: 50px;
  border-radius: 50px;
}

.menu {
  display: none;
}
.iconMenu {
  display: none;
}
@media (max-width: 600px) {
  .header .logo a img {
    width: 50px;
  }
}
@media (max-width: 720px) {
  .menu {
    display: none;
  }
  .iconMenu {
    display: block;
    font-size: 25px;
    cursor: pointer;
  }
  .header .logo strong {
    display: none;
  }
  .header .profile img {
    display: none;
  }
  .header span {
    font-size: 1rem;
  }
}
