.hidden {
  display: none;
}
.loading {
  display: flex;
  justify-content: center;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.action_section {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content table {
  margin: 20px 0;
}
