h3:first-child {
  margin-bottom: 1rem;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  font-size: 0.9rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formUpdateUser {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formUpdateUser .sectionInput {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.formUpdateUser .sectionInput label {
  font-size: 0.9rem;
  margin-top: 5px;
}
.formUpdateUser .sectionInput button {
  margin-top: 10px;
}
/*
.formUpdateUser .sectionInput button:last-child {
  background-color: var(--red);
}
.formUpdateUser .sectionInput button:last-child:hover {
  background-color: var(--red-dark);
} */
@media (max-width: 600px) {
  .formUpdateUser .sectionInput {
    width: 300px;
  }
  .formUpdateUser .sectionButtons button {
    width: 100px;
  }
}
