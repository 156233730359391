.hidden {
  display: none;
}
.optionMenu {
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.411);
}
.optionMenu a {
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0;
}
.optionMenu a:hover {
  background-color: var(--verde_200);
  color: white;
}
.optionMenu a:hover i {
  color: white;
}
/* .optionMenu a:focus {
  background-color: rgba(0, 0, 0, 0.199);
  color: white;
 
} */
.optionMenu a i {
  margin-right: 1rem;
  font-size: 2rem;
}
/* DROPDOWN  */

.dropdown .optionMenu .labelIcons {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
}
.optionMenu .labelIcons span {
  display: flex;
  align-items: center;
}
.optionMenu .labelIcons span i {
  margin-right: 1rem;
  font-size: 2rem;
}

/*.optionMenu .labelIcons i {
  font-size: 1.4rem;
} */
.dropdown {
  cursor: pointer;
  /* position: relative; */
}

.dropdown .dropdownChildren {
  background-color: white;
  /* z-index: 9999;
  position: relative;
  left: 300px;
  top: 0;
  width: 250px; */
}
.dropdown .dropdownChildren .optionMenu a span {
  margin-right: 30px;
}
/*.dropdown:hover .dropdownChildren {
  display: block;
}
*/
