.container {
  width: 100%;
  height: 100vh;
}
.closeMenu {
  width: 25%;
  background-color: var(--home);
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.411);
  /*z-index: 9999;*/
}
.showMenu {
  width: 25%;
  background-color: var(--home);
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.411);
  /*z-index: 9999;*/
}
.container .contentUserTipo5 {
  display: flex;
  width: 100%;
  height: calc(100% - 9rem);
}
.container .content {
  display: flex;
  width: 100%;
  height: calc(100% - 5rem);
}

@media (max-width: 992px) {
  .showMenu {
    width: 100%;
  }
  .closeMenu {
    display: none;
  }
}
