/* ------------------- Barra de menus */
.top_menu {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.411);
  padding: 0 1rem;
  background-color: var(--home);
}
/* -------------------  Caixa que contem todas as opções */
.row {
  display: flex;
  align-items: center;
}

/*.row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
}
.col_2 {
  grid-column: span 1;
}
.col_3 {
  grid-column: span 3;
} */

/* ------------------- Opção (Titulo de cada opção) */

.option {
  white-space: nowrap;
  cursor: pointer;
}

.option a {
  padding: 1rem;
  display: block;
}
.option a i {
  margin-right: 5px;
}
.option:hover {
  background-color: #bebebe;
}
