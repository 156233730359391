.hidden {
  display: none;
}
.loading {
  display: flex;
  justify-content: center;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.content a button:first-child {
  margin-bottom: 5px;
}
.content table {
  margin: 20px 0;
}
.content .wrap {
  border: 1px solid #000;
  padding: 1rem;
  width: 100%;
}
.content .wrap .filter_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.content .section_button {
  display: flex;
  justify-content: center;
}
.content .section_button button {
  margin: 10px 10px 0 0;
}
.content .selection_section {
  display: flex;
  align-items: center;
}

.table_pagination {
  display: flex;
  justify-content: center;
}

.table_pagination .table_pagination_buttons {
  display: flex;
  justify-content: space-between;
}

.table_pagination .table_pagination_buttons span {
  margin: 0 10px 0 10px;
  display: block;
  padding: 10px;
  background-color: var(--verde_200);
  color: var(--background);
  width: 100px;
  text-align: center;
  cursor: default;
}

.table_pagination .table_pagination_buttons button i {
  color: var(--background);
}
