h3:first-child {
  margin-bottom: 1rem;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  font-size: 0.9rem;
}
/* .content {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.content .form {
  width: 400px;
}
.content .form .field_column {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.content .form .field_column label {
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .content .form {
    width: 300px;
  }
  .content .sectionButtons button {
    width: 100%;
  }
}
