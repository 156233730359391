.hidden {
  display: none;
}
.loading {
  display: flex;
  justify-content: center;
}
.alertError {
  color: var(--vermelho_200);
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.content .wrap {
  border: 1px solid #000;
  padding: 1rem;
  width: 100%;
}
.content .wrap .filter_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.content .button_section button {
  margin: 10px 5px 0 0;
}
.content .table_content {
  display: flex;
}
.content .table_content table {
  margin-right: 10px;
}
.content table {
  margin: 20px 0;
}
