h3:first-child {
  margin-bottom: 1rem;
}
.btnTopo button {
  width: 200px;
  margin-bottom: 10px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content table {
  width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .th_tr_Responsive {
    display: none;
    text-align: center;
  }
}
