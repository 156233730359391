h3:first-child {
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
/* 
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
 */
.form {
  margin-top: 20px;
}
.form .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form .content section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 420px;
}
.form .content section label {
  margin-top: 10px;
}
.form .content section:last-child {
  margin-top: 10px;
}
.backButton {
  background-color: var(--verde_200);
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 15px;
}
.backButton i {
  color: #fff;
}
.table {
  width: 100%;
  margin-top: 20px;
}
/*.table tr,
th,
.tdCenter {
  text-align: center;
} */
.table tr:last-child {
  text-align: right;
}
@media (max-width: 600px) {
  .form .content section {
    width: 300px;
  }
}
